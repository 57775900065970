.product-page-container {
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: var(--a);
}
.category-filter {
  width: 100%;
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.category-slider {
  width: 25%;
  padding: 20px;
  background-color: var(--p);
}
.category-slider ul li {
  margin: 10px 0;
  color: #fff;
  padding: 10px;
  cursor: pointer;
}
.category-slider ul li.active {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.products-container {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  gap: 30px;
}
.product-box {
  width: 17%;
  background-color: var(--p);
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #06294b;
  }
}
.product-box .product-img {
  width: 100%;
  height: 150px;
  margin-bottom: 10px;
}
.product-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.product-box h5 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 992px) {
  .product-page-container {
    padding: 50px 20px;
    position: relative;
    overflow: hidden;
  }
  .products-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 30px 0;
  }
  .product-box {
    width: 20%;
    margin-bottom: 20px;
  }
  .category-slider {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    bottom: -120%;
    transition: 0.2s all ease;
    z-index: 999;
  }
  .category-slider.active {
    bottom: -50%;
  }
}
@media screen and (max-width: 786px) {
  .products-container {
    gap: 0;
  }
  .product-box {
    width: 25%;
  }
  .product-box .product-img {
    width: 100%;
    height: 120px;
  }
}
@media screen and (max-width: 600px) {
  .product-box {
    width: 45%;
  }
}
@media screen and (max-width: 300px) {
  .product-box {
    width: 100%;
  }
}
