.wallet-dash {
  display: flex;
  padding: 30px 0px;
  padding-top: 0;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}
.wallet-dash-card {
  width: 30%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
  cursor: pointer;
}
.wallet-dash-card-one {
  background-color: var(--a);
  color: #fff;
}
.add-money {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.add-money span {
  color: var(--t);
}
.bar-img {
  width: 95%;
  margin-top: 20px;
  border: 2px solid var(--t);
}
.txn-details {
  width: 50%;
}
.bar-img img {
  width: 100%;
}
.pay-online {
  background-color: var(--a);
  margin-top: 10px;
  padding: 10px;
  outline: transparent;
  border: transparent;
  color: #fff;
}

@media screen and (max-width: 992px) {
  .wallet-dash-card {
    width: 100%;
  }
  .bar-img {
    width: 100%;
  }
  .txn-details {
    width: 100%;
  }
}
