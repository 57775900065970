.hero-cont {
  background-color: #acc5e9;
  background-color: #4b89dc;
  position: relative;
  overflow: hidden;
  background: url("../../img/home/hero.png");
}
/* .hero-cont::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -95%;
  width: 100%;
  height: 500px;
  border-radius: 100%;
  background-color: #acc5e9;
} */
.hero-container {
  padding: 50px;
  overflow: hidden;
  position: relative;
}
.hero-container img {
  border-radius: 10px;
  object-fit: cover;
}
.hero-slider-btns {
  position: absolute;
  left: 50%;
  bottom: 15px;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 20px;
  z-index: 99;
  cursor: pointer;
}
.hero-slider-btns .icon {
  background-color: #000;
  color: #fff;
  padding: 10px;
  font-size: 50px;
  border-radius: 100px;
}
.hero-container .carousel-inner img {
  border-radius: 50px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}
.slick-slide {
  scale: 0.9;
  transition: 0.3s all ease;
}
.slick-center {
  scale: 1.1;
}
.slick-arrow {
  display: none !important;
}
.slick-dots li button:before {
  color: #fff !important;
}
.slick-dots {
  bottom: -20px !important;
}
@media screen and (max-width: 700px) {
  .hero-container {
    padding: 20px;
    padding-bottom: 40px;
  }
  .hero-slider-btns {
    display: none;
  }
  /* .hero-cont::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -95%;
    width: 100%;
    height: 400px;
    border-radius: 100%;
    background-color: #acc5e9;
  } */
}
