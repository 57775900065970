.maintenance {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
}
.maintenance input {
  padding: 10px;
}
.maintenance button {
  width: 100%;
  padding: 10px;
  outline: transparent;
  border: transparent;
  background-color: #000;
  color: #fff;
}

.toggle-icon {
  width: 80px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--p);
  padding: 5px;
  transition: 0.3s all ease;
  cursor: pointer;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
.toggle-icon.active {
  justify-content: flex-end;
}
.toggle-icon .circlee {
  position: absolute;
  top: 50%;
  left: 5%;
  width: 35px;
  height: 35px;
  transition: 0.3s all ease;
  background-color: red;
  transform: translateY(-50%);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle-icon .circlee.active {
  left: 50%;
  background-color: greenyellow;
}
