.contact-page-container {
  padding: 50px;
  overflow: hidden;
  color: #000;
}
.contact-page-container textarea {
  color: #fff;
}
.contact-image {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.google-map {
  height: 450px;
  position: relative;
  padding: 0;
  overflow: hidden;
}
.contact-page-container img {
  width: 80%;
  height: 100%;
  object-fit: cover;
}
.contact-form {
  padding: 50px 20px;
}
.contact-form input {
  border: 1px solid grey !important;
  outline: transparent;
  border-radius: 5px;
  padding: 10px;
  background-color: #eee;
  color: #333;
  width: 100%;
}
.contact-form select {
  border: 1px solid grey !important;
  outline: transparent;
  border-radius: 5px;
  padding: 10px;
  background-color: #eee;
  color: #333;
  width: 100%;
}
.contact-form input::placeholder {
  color: #333;
}
.contact-form textarea {
  width: 100%;
  outline: transparent;
  border: 1px solid grey !important;
  background-color: #eee;
  border-radius: 10px;
  resize: none;
}
.contact-form textarea::placeholder {
  color: #333;
}

.contact-address-email {
  padding: 50px 20px;
  background-color: var(--s);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.c-address {
  width: 30%;
  padding: 40px;
  background-color: var(--p);
  color: #fff !important;
  border-radius: 20px;
  border: 2px solid #ffffff;
  transition: all 0.4s;
  &:hover {
    background-color: var(--s);
    border: 2px solid #fff;
    color: #fff;
    p {
      color: #fff;
    }
    .icon {
      color: #fff;
    }
  }
}

@media screen and (max-width: 992px) {
  .contact-form {
    padding: 50px 0px;
  }
  .c-address {
    width: 100%;
  }
}

.loading.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b8b8b8;
}

.loading.active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
    to left,
    transparent,
    rgba(255, 255, 255, 0.5),
    transparent
  );

  animation: loading 1s infinite;
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}
