.p-info-container {
  padding: 50px;
  background-color: var(--s);
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  color: #fff;
  background: linear-gradient(to top, #4a89dc, #fff);
  overflow: hidden;
}
.pro-img {
  width: 30%;
  width: 250px;
  height: 250px;
}
.pro-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.pro-content {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.pro-content h2 {
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
.pro-content h6 {
  text-align: center;
}
.pro-fav {
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.package-details {
  display: flex;
  flex-wrap: wrap;
}
.package-container {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
}
.package-container .amount {
  width: 48%;
  border-radius: 10px;
  padding: 20px 0;
  color: #000;
  padding-left: 10px;
  background-color: #eee;
  margin-bottom: 20px;
  cursor: pointer;
}
.package-container .amount span {
  font-weight: 700;
}
.package-container .amount.active {
  border: 2px solid #000;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}
.order-info {
  width: 40%;
  padding: 50px;
  color: #fff;
}
.pack-info {
  background-color: #eee;
  color: #000;
  padding: 20px;
  border-radius: 10px;
}
.pack-info .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
}
.p-check-btn {
  outline: transparent;
  border: transparent;
  border-radius: 100px;
  background-color: var(--p);
  color: #fff;
  font-weight: 800;
  padding: 15px 20px;
  width: 100%;
  margin-top: 10px;
}
.pro-desc-container {
  width: 60%;
  padding: 50px;
  color: #fff;
  padding-top: 0;
}
.pro-desc {
  background-color: #eee;
  padding: 20px;
  border-radius: 10px;
  color: #000;
}
.pro-desc span {
  white-space: pre-wrap;
}
.related-games {
  color: #fff;
  padding: 50px;
  padding-top: 0;
}
.cart {
  display: flex;
  justify-content: space-between;
}
.cart-icon {
  position: relative;
}
.cart-icon span {
  position: absolute;
  top: -10px;
  right: -20px;
  background-color: var(--t);
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.cart .icon {
  font-size: 30px;
}
.del-icon {
  color: var(--t);
  cursor: pointer;
}

@media screen and (max-width: 992px) {
  .pro-content,
  .pro-fav,
  .pro-img {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .pro-content {
    padding-bottom: 10px;
  }
  /* package  */
  .package-container,
  .order-info,
  .pro-desc-container {
    width: 100%;
    padding-bottom: 0;
  }
  .pro-desc-container {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 786px) {
  /* package  */
  .package-container,
  .order-info,
  .pro-desc-container {
    width: 100%;
    padding: 50px 20px;
    padding-bottom: 0;
  }
}

/* ================================================================================ */

.product-info-container {
  padding: 80px 150px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  background-color: var(--s);
  color: #fff;
}
.product-info-img-slider {
  width: 7%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-info-img-slider img {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  object-fit: cover;
}
.product-info-img {
  width: 30%;
  height: 350px;
}
.product-info-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product-info-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
}
.product-info-content select {
  padding: 10px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 50%;
}
.player-tag {
  padding: 10px;
  width: 100%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 100px;
  color: #000 !important;
}
.player-select {
  padding: 10px;
  width: 100%;
  outline: transparent;
  border: 1px solid grey;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 100px;
}
.product-info-content h3 {
  text-transform: uppercase;
}
.add-to-cart-btn {
  width: 50%;
  background-color: var(--s);
  color: #000;
  padding: 10px;
  outline: transparent;
  border: transparent;
  margin-top: 10px;
  border: 1px solid white;
  &:hover {
    opacity: 0.8;
  }
}
.safe-checkout-img {
  width: 35%;
  margin-top: 10px;
}
.product-desc-delivery-details {
  padding: 50px 20px;
}
.tab-btns-desc {
  display: flex;
}
.tab-btns-desc .tab {
  padding: 10px;
  text-align: center;
  width: 50%;
  background-color: #eee;
  cursor: pointer;
}
.tab-btns-desc .tab.active {
  background-color: #ebef29;
}
.product-info-details {
  margin-top: 20px;
  min-height: 250px;
}

.p-amount {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
/* ====================== PAYMENT  */
.payment {
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
}
.payment .wallet.active {
  background-color: #000;
  color: #fff;
}
.payment .upi.active {
  background-color: #000;
  color: #fff;
}
.payment h4 {
  font-size: 20px;
}
.wallet,
.upi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  color: #000;
  padding: 10px;
  border-radius: 15px;
  cursor: pointer;
}
.wallet .icon {
  font-size: 30px;
}
.payment img {
  width: 100px;
}

@media screen and (max-width: 992px) {
  .product-info-container {
    padding: 50px 20px;
  }
  .product-info-img-slider {
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
  .product-info-img-slider img {
    width: 20%;
    margin-right: 10px;
  }
  .product-info-img {
    width: 100%;
  }
  .product-info-content {
    width: 100%;
    padding-left: 0;
  }
  .select-brand select {
    width: 100%;
  }
  .add-to-cart-btn {
    width: 100%;
  }
  .safe-checkout-img {
    width: 100%;
  }
  .product-info-content select,
  .player-tag {
    width: 100%;
  }
}

/* ======================= EFFECT */
.area {
  position: relative;
}
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
/* ========================================================= BG EFFECT  */
